<template>
  <div class="login-container">
    <el-form
        class="login-form"
        ref="loginForm"
        :rules="loginRules"
        :model="loginForm"
        auto-complete="on"
    >
      <h1 class="title">pipbo的个人技术文档</h1>
      <el-form-item prop="userName">
        <el-input
            ref="username"
            v-model="loginForm.userName"
            placeholder="用户名"
            name="username"
            type="text"
            tabindex="1"
            prefix-icon="el-icon-user-solid"
        />
      </el-form-item>
      <el-form-item prop="password">
        <el-input
            ref="password"
            v-model="loginForm.password"
            type="password"
            placeholder="密码"
            name="password"
            tabindex="2"
            prefix-icon="el-icon-lock"
            @keyup.enter.native="handleLogin"
        />
      </el-form-item>
      <el-button
          type="primary"
          class="login-btn"
          @click="handleLogin"
      >登录</el-button>
    </el-form>
    <div class="footer">
      备案号：<a href="https://beian.miit.gov.cn/#/Integrated/index">蜀ICP备19039462号-1</a>
    </div>
  </div>
</template>

<script>
import {login} from '@/api/user'

export default {
  name: 'Login',
  data() {
    return {
      loginForm: {
        userName: '',
        password: ''
      },
      loginRules: {
        userName: [
          { required: true, message: '用户名不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ]
      },
    }
  },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate(async validate => {
        if (validate) {
          const res = await login()
          const farmData = res[this.loginForm.userName]
          if(farmData && farmData['user']['password'] === this.loginForm.password) {
            sessionStorage.setItem('userName', this.loginForm.userName)
            this.$router.push(`/farm`)
          } else {
            this.$message({
              type: 'error',
              message: '账号或密码错误'
            })
          }
        } else {
          return false
        }
      })
    },
    showPwd() {}
  }
}
</script>
<style lang="scss">
/*重置element*/
$bg: #283443;
$light_gray: #fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

.login-container {
  .el-input {
    input {
      background: transparent;
      border: none;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;

    .title {
      margin-bottom: 30px;
      text-align: center;
      color: #fff;
      font-size: 24px;
      font-weight: bold;
    }

    .login-btn {
      width: 100%;
    }
  }
  .footer {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #ccc;
    a {
      color: #ccc;
    }
  }
}
</style>